import { Box, Tooltip } from "@mui/material";
import { FeatureLike } from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import MVT from "ol/format/MVT";
import { Geometry } from "ol/geom";
import "ol/ol.css";
import { Fill, Stroke, Style, Text } from "ol/style";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { RFeature, RFeatureUIEvent, RLayerTile, RLayerVector, RLayerVectorTile, RMap, ROSM, RStyle } from "rlayers";
import { environments } from "../../../environments";
import { ImobiliarioEdificacao, ImportacoesTiff } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { isCurrentColorModeDark } from "../../../providers/Theme";
import { getImportacoesTiff } from "../../../services/api";
import { getTokens } from "../../../services/data";
import { getAll, getValue } from "../../../services/db";
import { getLastPosition, saveLastPosition } from "../../../services/utils";

export default function Map() {
  const [view, setView] = useState(getLastPosition() || { center: [0, 0], zoom: 1 });
  const [lote, setLote] = useState<Geometry>();
  const [edificacao, setEdificacao] = useState<Geometry>();
  const [edificacaoImobiliario, setEdificacaoImobiliario] = useState<Geometry[]>([]);
  const [basemaps, setBasemaps] = useState<ImportacoesTiff[]>();
  const [currentBasemapIdx, setCurrentBasemapIdx] = useState<number>(0);
  const [isHoveringLayerSwitcher, setIsHoveringLayerSwitcher] = useState<boolean>(false);
  const { metadata, setMetadata } = useContext(DashboardContext);
  const history = useHistory();

  const map = useRef<RMap>(null);

  const mapStyles = {
    lote: `#BCBCBC`,
    logradouro: (feature: FeatureLike) => {
      const nome = feature.get("nome");
      const id = feature.get("id");

      const text = new Text({
        text: `${nome} (${id})`,
        font: '16px "Calibri"',
        fill: new Fill({ color: "#ffffff" }),
        stroke: new Stroke({ color: "#000000", width: 4 }),
        placement: "line",
      });

      const mainStyle = new Style({
        stroke: new Stroke({
          color: "rgb(255,255,255)",
          width: 3,
        }),
        text: text,
      });

      const baseStyle = new Style({
        stroke: new Stroke({
          color: "#000000",
          width: 5,
        }),
      });

      return [baseStyle, mainStyle];
    },
    loteSelecionado: `#FFE600`,
    edificacaoOnline: `#ffcfcf`,
    edificacaoLote: `#BCBCBC`,
    edificacaoImobiliario: `#C77272`,
    edificacaoSelecionada: `#FA0707`,
  };

  const mapAuth = environments.urlApiGateway
    ? `x_auth_token=${getTokens()["x-auth-token"]}&x_tn_token=${getTokens()["x-tn-token"]}`
    : "";

  useEffect(() => {
    if (!basemaps) {
      getImportacoesTiff().then((data) => {
        if (!data) return;
        data.filter((e) => e.url);
        setBasemaps(data);
      });
    }

    if (getLastPosition()) setView(getLastPosition() || view);
  }, []);

  useEffect(() => {
    if (getLastPosition()) {
      saveLastPosition(view);
    }
  }, [view]);

  useEffect(() => {
    try {
      if (metadata.id_lote) {
        let feature = new GeoJSON().readFeature(getValue(metadata.id_lote!, "lote", "geom"), {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        //@ts-ignore
        setLote(feature.getGeometry());
      }
    } catch (error) {
      console.error(error);
    }

    if (metadata.id_edificacao) {
      let feature = new GeoJSON().readFeature(getValue(metadata.id_edificacao!, "edificacao", "geom"), {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      //@ts-ignore
      setEdificacao(feature.getGeometry());
    }

    if (metadata.id_imobiliario) {
      const imobiliario_edificacao = getAll("imobiliario_edificacao") as ImobiliarioEdificacao[];

      if (imobiliario_edificacao && imobiliario_edificacao.length > 0) {
        const edificacoes = imobiliario_edificacao
          .filter((e) => String(e.id_imobiliario) === metadata.id_imobiliario)
          .map((item) => item.id_edificacao);

        setEdificacaoImobiliario([]);

        edificacoes.forEach((item) => {
          if (!getValue(String(item), "edificacao", "geom")) return;

          let feature = new GeoJSON().readFeature(getValue(String(item), "edificacao", "geom"), {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          });
          //@ts-ignore
          setEdificacaoImobiliario((old) => [...old, feature.getGeometry()!]);
        });
      }
    }
  }, [metadata]);

  useEffect(() => {
    if (lote && !getLastPosition()) {
      lote.getExtent() && map.current?.ol.getView().fit(lote.getExtent()!, { padding: [50, 50, 50, 50], maxZoom: 20 });
      saveLastPosition({ center: [lote.getExtent()![0], lote.getExtent()![1]], zoom: 20 });
    }
  }, [lote]);

  const handleClick = useCallback((e: RFeatureUIEvent) => {
    if (e.target.getProperties().id) {
      history.push("/dashboard/" + e.target.getProperties().id, {
        id: e.target.getProperties().id,
      });
    }
  }, []);

  return (
    <RMap
      ref={map}
      width={"100%"}
      height={"calc(100vh - 50px)"}
      initial={view}
      view={[view, setView]}
      noDefaultControls
    >
      {isCurrentColorModeDark() ? (
        <RLayerTile url={"http://basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"} zIndex={10} />
      ) : (
        <ROSM zIndex={10} />
      )}

      {basemaps && basemaps.length > 0 && basemaps[currentBasemapIdx].url && (
        <RLayerTile
          url={`${basemaps[currentBasemapIdx].url}{z}/{x}/{y}.${basemaps[currentBasemapIdx].extensao}`}
          zIndex={11}
        />
      )}

      <RLayerVectorTile
        url={environments.urlApiGateway + "/tiles/cadastro.lote/{z}/{x}/{y}.pbf?" + mapAuth}
        format={new MVT()}
        zIndex={100}
        onClick={handleClick}
      >
        <RStyle.RStyle>
          <RStyle.RStroke color={mapStyles.lote} width={6} />
          <RStyle.RFill color="rgba(20,20,20,0)" />
        </RStyle.RStyle>
      </RLayerVectorTile>

      <RLayerVectorTile
        url={environments.urlApiGateway + "/tiles/cadastro.edificacao/{z}/{x}/{y}.pbf?" + mapAuth}
        zIndex={101}
        format={new MVT()}
      >
        <RStyle.RStyle>
          <RStyle.RStroke color={mapStyles.edificacaoOnline} width={1} />
          <RStyle.RFill color="rgba(20,20,20,0)" />
        </RStyle.RStyle>
      </RLayerVectorTile>

      <RLayerVectorTile
        url={environments.urlApiGateway + "/tiles/cadastro.logradouro/{z}/{x}/{y}.pbf?" + mapAuth}
        format={new MVT()}
        zIndex={200}
        style={(feature: FeatureLike) => mapStyles.logradouro(feature)}
      ></RLayerVectorTile>

      {lote && (
        <RLayerVector zIndex={102}>
          <RFeature geometry={lote}>
            <RStyle.RStyle>
              <RStyle.RStroke color={mapStyles.loteSelecionado} width={5} />
              <RStyle.RFill color={mapStyles.loteSelecionado + "10"} />
            </RStyle.RStyle>
          </RFeature>
        </RLayerVector>
      )}

      {edificacaoImobiliario.length > 0 &&
        edificacaoImobiliario.map((e, index) => {
          return (
            <RLayerVector key={index} zIndex={103}>
              <RFeature geometry={e}>
                <RStyle.RStyle>
                  <RStyle.RStroke color={mapStyles.edificacaoImobiliario} width={3} />
                  <RStyle.RFill color={mapStyles.edificacaoImobiliario + "10"} />
                </RStyle.RStyle>
              </RFeature>
            </RLayerVector>
          );
        })}

      {edificacao && (
        <RLayerVector zIndex={104}>
          <RFeature geometry={edificacao}>
            <RStyle.RStyle>
              <RStyle.RStroke color={mapStyles.edificacaoSelecionada} width={3} />
              <RStyle.RFill color={mapStyles.edificacaoSelecionada + "10"} />
            </RStyle.RStyle>
          </RFeature>
        </RLayerVector>
      )}

      <Box
        id="legenda"
        sx={{
          position: "absolute",
          top: "calc(100vh - 80px - 10px) ",
          left: `20px`,
        }}
      ></Box>

      {basemaps && basemaps.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            cursor: "pointer",
            height: isHoveringLayerSwitcher ? `unset` : "80px",
            transition: "height 0.3s ease-in-out",
            display: isHoveringLayerSwitcher ? "grid" : "block",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "10px",
          }}
          onMouseEnter={() => setIsHoveringLayerSwitcher(true)}
          onMouseLeave={() => setIsHoveringLayerSwitcher(false)}
        >
          {isHoveringLayerSwitcher ? (
            <>
              {basemaps
                .filter((b) => b.url)
                .map((b, i) => {
                  return (
                    <Box
                      sx={{
                        paddingBottom: isHoveringLayerSwitcher ? "5px" : 0,
                        display: "block",
                        height: "auto"
                      }}
                      onClick={() => setCurrentBasemapIdx(i)}
                    >
                      <Tooltip title={basemaps[i].nome} placement="top">
                        <Box
                          component="img"
                          sx={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                          }}
                          src={basemaps[i].preview || `https://via.placeholder.com/80x80/333840?text=${basemaps[i].nome}`}
                          alt="Basemap"
                        />
                      </Tooltip>
                    </Box>
                  );
                })}
            </>
          ) : (
            <Box
              sx={{
                paddingBottom: isHoveringLayerSwitcher ? "5px" : 0,
              }}
            >
              <Tooltip title={basemaps[currentBasemapIdx].nome} placement="top">
                <Box
                  component="img"
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                  }}
                  src={
                    basemaps[currentBasemapIdx].preview ||
                    `https://via.placeholder.com/80x80/333840?text=${basemaps[currentBasemapIdx].nome}`
                  }
                  alt="Basemap"
                />
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
    </RMap>
  );
}
