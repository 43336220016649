import { getTenantConfig } from "../services/api";
import toast from "react-hot-toast";
import { setMunicipio } from "../services/data";
import { clear } from "../services/db";

export async function checkAutenticationByUrl() {
  clear();
  let params = window.location.search.split("&");

  if (params.length > 2) {
    params[1] = "";
    params.shift();
  }
  params[0] = params[0].replace("?", "");

  params.map((param, index) => {
    if (param.includes("x-auth-token=")) {
      localStorage.setItem("xAuthToken", param.replace("x-auth-token=", ""));
    }

    if (param.includes("x-tn-token=")) {
      localStorage.setItem("xTnToken", param.replace("x-tn-token=", ""));
    }
  });
  const check = params.filter((param) => param.includes("x-auth-token=") || param.includes("x-tn-token=")).length !== 0;

  if (check) {
    const projectInfo = await getTenantConfig();
    if (!projectInfo) {
      toast.error("Não foi possível obter as configurações de projeto");
    }

    setMunicipio(projectInfo!.cidade);
    localStorage.setItem("errors", "{}");
  }

  return check;
}
